<template>
  <b-form @submit.stop.prevent="confirm">
    <b-modal
      :id="`insertUpdateModal_${id}`"
      centered
      :size="size"
      @hide="close"
    >
      <template #modal-title> {{ title }} </template>
      <div :disabled="loading">
        <slot></slot>
      </div>
      <template #modal-footer>
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button
            type="reset"
            variant="link"
            @click="close"
            class="text-dark text-hover-dark-50 text-decoration-none"
            >İptal</b-button
          >

          <b-button
            type="submit"
            variant="primary"
            class="ml-auto"
            ref="submitButton"
            @click="confirm"
            >{{ confirmLabel }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
export default {
  name: 'InsertUpdateModal',
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    size: {
      type: String,
      default: 'md',
    },
    show: Boolean,
    title: String,
    resetForm: Boolean,
    confirmLabel: {
      type: String,
      default: 'KAYDET',
    },
  },
  data() {
    return {
      loading: false,
      submitButton: null,
    };
  },
  methods: {
    confirm() {
      this.submitButton = this.$refs['submitButton'];
      this.$spinnerLoader.addSpinnerLoader(this.submitButton);
      this.loading = true;

      this.$bvModal.show(`insertUpdateModal_${this.id}`);

      this.$emit('confirm');
    },
    close() {
      this.$bvModal.hide(`insertUpdateModal_${this.id}`);
      this.$emit('closeDetail', false);
    },
  },
  watch: {
    resetForm(newVal) {
      if (newVal) {
        this.submitButton = this.$refs['submitButton'];
        this.$spinnerLoader.removeSpinnerLoader(this.submitButton);
        this.loading = false;
      }
    },
    show(newVal) {
      if (newVal) {
        this.$bvModal.show(`insertUpdateModal_${this.id}`);
      } else this.$bvModal.hide(`insertUpdateModal_${this.id}`);
    },
  },
};
</script>
